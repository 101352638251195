// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zaTXfQZ8j0qLibhMOULu .gx-slidein-body{padding-bottom:0px}`, "",{"version":3,"sources":["webpack://./src/app.styles.module.scss"],"names":[],"mappings":"AAAA,uCACI,kBAAA","sourcesContent":[".secondarySlideInOverlay :global(.gx-slidein-body) {\n    padding-bottom: 0px\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"secondarySlideInOverlay": `zaTXfQZ8j0qLibhMOULu`
};
export default ___CSS_LOADER_EXPORT___;
