import { useCallback, useContext, useEffect, useState } from "react";
import classNames from "classnames";
import { validate as emailValidator } from "email-validator";
import { useTranslation } from "react-i18next";
import { CheckmarkIconV2 } from "gx-npm-icons";
import {
  FreeTrialRole,
  getAsyncRequest,
  InitUserRole,
  InitUserRoleLabel,
  postAsyncRequest,
  useUserState,
} from "gx-npm-lib";
import { TextFieldWithSelectCombo } from "gx-npm-ui";
import DialogNotEmailDomainMatch from "./dialog-not-email-domain-match.component";
import { TeamManagementAppContext } from "../../app.context";
import { TeamMembersResponse } from "../../app.types";

const AddUserForm = () => {
  const { t } = useTranslation();
  const { initiativeId, setNewlyDisplayedMemberEmail, setNotificationEmail, setTeamMembers, teamMembers } =
    useContext(TeamManagementAppContext);
  const [isError, setIsError] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [roleIndex, setRoleIndex] = useState(0);
  const [value, setValue] = useState("");
  const [isDuplicateEmail, setIsDuplicateEmail] = useState(false);
  const [isNotEmailDomainMatchOpen, setIsNotEmailDomainMatchOpen] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const { email: sourceEmail, freeTrialRole } = useUserState();
  const isTrialUser = !!freeTrialRole && Object.values(FreeTrialRole).includes(freeTrialRole);

  useEffect(() => {
    setIsConfirmed(false);
  }, [roleIndex, value, setIsConfirmed]);

  useEffect(() => {
    if (formErrorMessage === "INVALID_EMAIL") {
      setIsError(true);
    }
  }, [formErrorMessage]);

  const createMenuItem = (selected: number) => {
    return [
      {
        index: 0,
        menuListIcon: selected === 0 && <CheckmarkIconV2 />,
        name: InitUserRoleLabel.VIEWER,
      },
      {
        index: 1,
        menuListIcon: selected === 1 && <CheckmarkIconV2 />,
        name: InitUserRoleLabel.CONTRIBUTOR,
      },
      {
        index: 2,
        menuListIcon: selected === 2 && <CheckmarkIconV2 />,
        name: InitUserRoleLabel.OWNER,
        disabled: isTrialUser,
        showTooltip: isTrialUser,
        tooltipOptions: {
          title: t("In the free trial version of BuySmart, evaluation ownership is not transferable."),
        },
      },
    ];
  };

  const sendInvite = () => {
    const getRoleByIndex = () => {
      let val = "";
      if (roleIndex === 0) {
        val = InitUserRole.VIEWER;
      } else if (roleIndex === 1) {
        val = InitUserRole.CONTRIBUTOR;
      } else if (roleIndex === 2) {
        val = InitUserRole.OWNER;
      }
      return val;
    };

    (async () => {
      setIsProcessing(true);

      try {
        const urlInvite = `/api/v4/initiatives/${initiativeId}/invite`;
        const responseInvite: TeamMembersResponse = await postAsyncRequest(urlInvite, {
          email: value,
          role: getRoleByIndex(),
        });
        if (responseInvite.status !== 201) {
          throw new Error(responseInvite.data?.systemMessage?.message);
        }
        handleValueUpdate("");
        setNewlyDisplayedMemberEmail(value);
        const urlTeam = `/api/v3/initiatives/${initiativeId}/team`;
        const responseTeam: TeamMembersResponse = await getAsyncRequest(urlTeam);
        if (responseTeam.status !== 200 || !Array.isArray(responseTeam.data?.data?.teamMembers)) {
          throw new Error(responseTeam.data?.systemMessage?.message);
        }
        setTeamMembers(responseTeam.data.data.teamMembers);
        setNotificationEmail(value);
      } catch (err) {
        setFormErrorMessage(err.message);
      }

      setIsProcessing(false);
    })();
  };

  const handleValueUpdate = useCallback(
    (val) => {
      setIsDuplicateEmail(false);
      setIsError(false);
      setFormErrorMessage("");
      setValue(val);
      const validEmailCheck = emailValidator(val);
      setIsValidEmail(validEmailCheck);
      for (const memberIdx of Object.keys(teamMembers)) {
        const email = teamMembers[memberIdx as unknown as number].email;
        setIsError(false);
        if (email && email.toLowerCase() === val.toLowerCase()) {
          setIsDuplicateEmail(true);
          setIsError(true);
          setIsValidEmail(false);
          break;
        }
      }
    },
    [teamMembers]
  );

  const handleSelectUpdate = (val: number) => {
    setRoleIndex(val);
  };

  const handleValueBlur = (val: string) => {
    handleValueUpdate(val.trim());
  };

  const handleCloseNotEmailDomainMatch = () => {
    setIsNotEmailDomainMatchOpen(false);
  };

  const handleSendNotEmailDomainMatch = () => {
    setIsConfirmed(true);
    setIsNotEmailDomainMatchOpen(false);
    sendInvite();
  };

  let textLowerHelper;
  if (!isError) {
    textLowerHelper = t("Enter a valid work email address.");
  } else if (isDuplicateEmail) {
    textLowerHelper = t("This user already has access to your initiative.");
  } else if (formErrorMessage === "INVALID_EMAIL") {
    textLowerHelper = t(
      "Please use a company-issued email address. Gmail, Yahoo, and other free email services are not accepted."
    );
  } else if (formErrorMessage === "INVALID_DOMAIN_FOR_OWNER") {
    textLowerHelper = t(
      "Team members with email domains different from the initiative creator's cannot be set to the owner role."
    );
  }
  return (
    <div className={classNames("gx-team-input-email")}>
      <TextFieldWithSelectCombo
        autoFocus={true}
        autoWidth={true}
        ariaLabel="email address input"
        disabledBtn={!isValidEmail}
        iconType={"arrowDynamic"}
        isError={isError}
        isLoading={isProcessing}
        onClickButton={sendInvite}
        onSelectDropdown={handleSelectUpdate}
        onTextBlur={handleValueBlur}
        onTextUpdate={handleValueUpdate}
        selectItems={createMenuItem(roleIndex)}
        textButton={t("Add")}
        textLowerHelper={textLowerHelper}
        textPlaceholder={t("Email address")}
        valueSelect={roleIndex}
        valueText={value}
      />
      <DialogNotEmailDomainMatch
        isOpen={isNotEmailDomainMatchOpen}
        onClose={handleCloseNotEmailDomainMatch}
        onConfirm={handleSendNotEmailDomainMatch}
        sourceEmail={sourceEmail}
        targetEmail={value}
        isConfirmed={isConfirmed}
      />
    </div>
  );
};

export default AddUserForm;
