import React, { RefObject, useContext } from "react";
import { useTranslation } from "react-i18next";
import { UUID } from "gx-npm-lib";
import ScoringManageListItem from "./scoring-assignments-manage-list-item.component";
import styles from "./scoring-assignments-manage-list.styles.module.scss";
import { TeamManagementAppContext } from "../../../app.context";

type ScoringManageListProps = {
  onClickCategory?: (clickRefObject: RefObject<HTMLElement>, catId: UUID, catName: string) => void;
  selectedCategory?: UUID;
};

const ScoringManageList: React.FC<ScoringManageListProps> = ({ onClickCategory = () => {}, selectedCategory = "" }) => {
  const { t } = useTranslation();
  const { scoringTeam } = useContext(TeamManagementAppContext);
  const { categories } = scoringTeam;
  const hasCategories = categories && categories.length > 0;

  const renderListItems = () => {
    if (!hasCategories) {
      return;
    }

    return categories.map((category = { evaluators: [], id: "", name: "" }) => {
      return (
        <ScoringManageListItem
          key={`manual-assign-list-${category.id}`}
          categoryId={category.id}
          categoryName={category.name}
          evaluators={category.evaluators}
          handleClick={onClickCategory}
          selectedCategory={selectedCategory}
        />
      );
    });
  };

  return (
    <ul aria-label={!hasCategories ? t("no categories") : ""} className={styles.scoringManageList}>
      {renderListItems()}
    </ul>
  );
};

export default ScoringManageList;
