import React from "react";
import classNames from "classnames";
import { Button, ButtonLoader } from "gx-npm-ui";
import styles from "./scoring-mode-change-dialog.styles.module.scss";
import { useTranslation } from "react-i18next";

type ScoringModeChangeFooterProps = {
  isEnabled?: boolean;
  isLoading?: boolean;
  onCancelClick?: () => void;
  onChangeConfirmed?: () => void;
  textButton?: string;
};

const ScoringModeChangeFooter: React.FC<ScoringModeChangeFooterProps> = ({
  isEnabled = true,
  isLoading = false,
  onCancelClick = () => {},
  onChangeConfirmed = () => {},
  textButton = "",
}) => {
  const { t } = useTranslation();

  const handleChangeClick = () => {
    if (isEnabled && !isLoading) {
      onChangeConfirmed();
    }
  };

  return (
    <div className={classNames(styles.pubDialogFooterRoot, "gx-score-mode-change-diag-cancel-btn")}>
      <Button rootClassName="btn-tertiary" disabled={!isEnabled || isLoading} onClick={() => onCancelClick()}>
        {t("CANCEL")}
      </Button>
      <div className={classNames("gx-score-mode-change-diag-confirm-btn")}>
        <ButtonLoader
          ariaLabel={t("change scoring mode button")}
          btnClass="btn-primary"
          disabled={!isEnabled || isLoading}
          handleButtonClick={handleChangeClick}
          isLoading={isLoading}
        >
          {textButton}
        </ButtonLoader>
      </div>
    </div>
  );
};

export default ScoringModeChangeFooter;
