// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stlMyhAsU8P7EsWDKzT6{display:flex;justify-content:flex-end;padding-top:20px}.stlMyhAsU8P7EsWDKzT6 button:last-child{margin-left:12px}.niOK2MZUo8KJITMvMFOj{color:var(--color-neutrals-coal);letter-spacing:.25px;line-height:24px}.niOK2MZUo8KJITMvMFOj span{font-size:16px;font-weight:var(--weight-semi-bold);font-variation-settings:"wght" var(--weight-semi-bold);letter-spacing:.25px;line-height:24px}`, "",{"version":3,"sources":["webpack://./src/sections/team-management/dialog-user-remove.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,wBAAA,CACA,gBAAA,CAGF,wCACE,gBAAA,CAGF,sBACE,gCAAA,CACA,oBAAA,CACA,gBAAA,CAGF,2BACE,cAAA,CACA,mCAAA,CACA,sDAAA,CACA,oBAAA,CACA,gBAAA","sourcesContent":[".footerContainer {\n  display: flex;\n  justify-content: flex-end;\n  padding-top: 20px;\n}\n  \n.footerContainer button:last-child {\n  margin-left: 12px;\n}\n  \n.bodyContainer {\n  color: var(--color-neutrals-coal);\n  letter-spacing: .25px;\n  line-height: 24px;\n}\n  \n.bodyContainer span {\n  font-size: 16px;\n  font-weight: var(--weight-semi-bold);\n  font-variation-settings: \"wght\" var(--weight-semi-bold);\n  letter-spacing: 0.25px;\n  line-height: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footerContainer": `stlMyhAsU8P7EsWDKzT6`,
	"bodyContainer": `niOK2MZUo8KJITMvMFOj`
};
export default ___CSS_LOADER_EXPORT___;
