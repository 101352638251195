import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import styles from "./dialog-user-remove.styles.module.scss";
import { GCOM_3606__fontUpdate } from "../../lib/feature-flags";

type DialogUserRemoveProps = {
  confirmText: string;
  isOpen: boolean;
  name: string;
  onCancel?: (_e: React.MouseEvent<HTMLButtonElement>) => void;
  onConfirm?: (_e: React.MouseEvent<HTMLButtonElement>) => void;
  title: string;
  text: string;
};

const DialogUserRemove: React.FC<DialogUserRemoveProps> = ({
  confirmText = "",
  isOpen = false,
  name = "",
  onCancel = (_e) => {},
  onConfirm = (_e) => {},
  title = "",
  text = "",
}) => {
  const { t } = useTranslation();
  const [isConfirmed, setIsConfirmed] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setIsConfirmed(false);
    }
  }, [isOpen]);

  const handleConfirm = (e: React.MouseEvent<HTMLButtonElement>) => {
    setIsConfirmed(true);
    onConfirm(e);
  };

  return (
    <Dialog
      open={isOpen}
      title={title}
      body={
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent>
              {t("Removing")}
              <TypographyComponent boldness={"semi"} element={"span"}>{` ${name} `}</TypographyComponent>
              {text}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <div className={styles.bodyContainer}>
              {t("Removing")}
              <span>{` ${name} `}</span>
              {text}
            </div>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      }
      footer={
        <div className={styles.footerContainer}>
          <Button ariaLabel={t("button cancel remove user")} onClick={onCancel} rootClassName="btn-tertiary">
            {t("CANCEL")}
          </Button>
          <Button
            ariaLabel={t("button confirm remove user")}
            disabled={isConfirmed}
            onClick={handleConfirm}
            rootClassName="primary-destructive-btn"
          >
            {confirmText}
          </Button>
        </div>
      }
    />
  );
};

export default DialogUserRemove;
