// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mMK75WASasKkzofsZrA8{padding-left:0;position:relative}`, "",{"version":3,"sources":["webpack://./src/sections/scoring-assignments/scoring-assignments-manage/scoring-assignments-manage-list.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,iBAAA","sourcesContent":[".scoringManageList {\n  padding-left: 0;\n  position: relative;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scoringManageList": `mMK75WASasKkzofsZrA8`
};
export default ___CSS_LOADER_EXPORT___;
