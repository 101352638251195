import React from "react";
import { Dialog } from "gx-npm-ui";
import styles from "./scoring-mode-change-dialog.styles.module.scss";
import { useTranslation } from "react-i18next";
import ScoringModeChangeFooter from "./scoring-mode-change-footer.component";
import classNames from "classnames";
import { ScoringMode } from "gx-npm-lib";

type ScoringModeChangeDialogProps = {
  isLoading?: boolean;
  isOpen: boolean;
  onCancelClick?: () => void;
  mode?: ScoringMode;
  onChangeScoringMode?: () => void;
};

const ScoringModeChangeDialog: React.FC<ScoringModeChangeDialogProps> = ({
  isLoading = false,
  isOpen = false,
  onCancelClick = () => {},
  mode = ScoringMode.NONE,
  onChangeScoringMode = () => {},
}) => {
  const { t } = useTranslation();

  let title = "";
  let bodyText = "";
  let buttonText = "";

  if (mode === ScoringMode.AUTO_ASSIGNMENT) {
    title = t("Change scoring mode to automatic?");
    bodyText = t(
      "Existing scores entered by you or other team members will be carried over and all team members with the role of Owner or Contributor will have access to their own vendor scoresheets."
    );
    buttonText = t("set to automatic scoring");
  } else if (mode === ScoringMode.MANUAL_ASSIGNMENT) {
    title = t("Change scoring mode to manual?");
    bodyText = t(
      "Existing scores entered by you or other team members will be carried over and all team members with the role of Owner or Contributor will receive scoring assignments for all categories."
    );
    buttonText = t("set to manual scoring");
  }

  const handleChangedConfirmed = () => {
    onChangeScoringMode();
  };

  const handleCancelClick = () => {
    if (!isLoading) {
      onCancelClick();
    }
  };

  return (
    <Dialog
      rootClassName={classNames(styles.root, "gx-score-mode-change-dialog")}
      body={<p className="p2">{bodyText}</p>}
      footer={
        <ScoringModeChangeFooter
          isEnabled={!isLoading}
          isLoading={isLoading}
          onCancelClick={handleCancelClick}
          onChangeConfirmed={handleChangedConfirmed}
          textButton={buttonText}
        />
      }
      handleClose={handleCancelClick}
      maxDialogWidth={"600px"}
      open={isOpen}
      title={title}
    />
  );
};

export default ScoringModeChangeDialog;
