import React, { useContext, useEffect, useState } from "react";
import classNames from "classnames";
import ListMemberRole from "./list-member-role.component";
import styles from "./team-member.styles.module.scss";
import { InitUserRole, useUserState, UUID } from "gx-npm-lib";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  Paragraph,
  SnackbarBanner,
  TooltipV2,
  TypographyComponent,
  useFeatureFlag,
  UsernameDisplay,
} from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import { TeamManagementAppContext } from "../../app.context";
import { GCOM_3479__functionalRoleUpdates } from "../../lib/feature-flags";
import FunctionalRoleComponent from "./functional-role/functional-role.component";

type TeamMemberProps = {
  email: string;
  fullName?: string;
  isVerifiedUser: boolean;
  triggerNotification?: boolean;
  userRole: InitUserRole;
  functionalRoleId: UUID;
  functionalRole: string;
};

const TeamMember: React.FC<TeamMemberProps> = ({
  email = "",
  fullName = "",
  isVerifiedUser = false,
  triggerNotification = false,
  userRole,
  functionalRole = "",
  functionalRoleId = "",
}) => {
  const { setNotificationEmail } = useContext(TeamManagementAppContext);

  const [highlightRemoveUser, setHighlightRemoveUser] = useState(false);
  const { email: sourceEmail } = useUserState();
  const isOwner = userRole === InitUserRole.OWNER;
  const isCurrentOwner = email === sourceEmail;
  const isContributor = userRole === InitUserRole.CONTRIBUTOR;
  const [isOpen, setIsOpen] = useState(triggerNotification);
  const [isEmailResent, setIsEmailResent] = useState(false);
  const { t } = useTranslation();
  const isGCOM3686FFOn = useFeatureFlag(GCOM_3479__functionalRoleUpdates);
  const userFullName = fullName.length > 29 ? fullName.substring(0, 29).concat("...") : fullName;
  const userEmailDisplay = email.length > 29 ? email.substring(0, 29).concat("...") : email;
  const disableTooltip = userEmailDisplay.length < 30;

  useEffect(() => {
    setIsOpen(triggerNotification);
  }, [triggerNotification]);

  const handleRemoveUserHighlight = (value: boolean) => {
    setHighlightRemoveUser(value);
  };
  const handleSnackbarClose = (value: boolean) => {
    setIsOpen(value);
    setIsEmailResent(value);
    setNotificationEmail("");
  };

  const successLabel = isEmailResent ? t("Email resent") : t("Email sent");
  return (
    <div
      aria-label={`Team Member`}
      className={classNames(
        isGCOM3686FFOn ? styles.root : styles.memberContainer,
        highlightRemoveUser && styles.removeUser,
        isContributor && "isContributor",
        "member-container-v2",
        highlightRemoveUser && isGCOM3686FFOn && styles.removeUserGCOM3686
      )}
      key={email}
    >
      <FeatureFlagBooleanContainer flagName={GCOM_3479__functionalRoleUpdates}>
        <FeatureFlagBooleanOn>
          <div className={styles.userDisplay}>
            <UsernameDisplay
              showName={false}
              email={userEmailDisplay}
              isUnknown={!isVerifiedUser}
              name={isVerifiedUser ? userFullName : "Pending"}
              showEmail={false}
            />
            <SnackbarBanner
              autoHideDuration={2000}
              isOpen={isOpen}
              keyName="mini-snackbar"
              message={successLabel}
              pointerActionCursor={true}
              setIsOpen={handleSnackbarClose}
              transitionDuration={{ enter: 800, exit: 2000 }}
              type={"SUCCESS"}
            />
            <div className={styles.userInfo}>
              <TypographyComponent
                styling={"p3"}
                boldness={"medium"}
                rootClassName={styles.userName}
                color={!isVerifiedUser ? "iron" : "carbon"}
              >
                {userFullName || t("Pending")}
              </TypographyComponent>

              <span className={styles.userInfoContainer}>
                {isOwner && isCurrentOwner ? (
                  <div className={styles.userRole}>
                    <TypographyComponent boldness={"medium"} styling={"p3"}>
                      {userRole}
                    </TypographyComponent>
                  </div>
                ) : (
                  <ListMemberRole
                    email={userEmailDisplay}
                    name={isVerifiedUser ? userFullName : userEmailDisplay}
                    onEmailResent={setIsEmailResent}
                    removeUserHighlight={handleRemoveUserHighlight}
                    userRole={userRole}
                  />
                )}
                <TooltipV2
                  deactivate={disableTooltip}
                  PopperProps={{ modifiers: { offset: { offset: "0, 12px" } } }}
                  title={email}
                  placement="bottom"
                >
                  <div>
                    <TypographyComponent
                      rootClassName={classNames(!disableTooltip && styles.emailHoverState)}
                      styling={"p4"}
                      boldness={"regular"}
                      color={"iron"}
                    >
                      {userEmailDisplay}
                    </TypographyComponent>
                  </div>
                </TooltipV2>
              </span>
            </div>
          </div>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <UsernameDisplay
            email={email}
            isUnknown={!isVerifiedUser}
            name={isVerifiedUser ? fullName : "Pending"}
            showEmail={true}
          />
          <SnackbarBanner
            autoHideDuration={2000}
            isOpen={isOpen}
            keyName="mini-snackbar"
            message={successLabel}
            pointerActionCursor={true}
            setIsOpen={handleSnackbarClose}
            transitionDuration={{ enter: 800, exit: 2000 }}
            type={"MINI_SUCCESS"}
          />
          <React.Fragment>
            {isOwner && isCurrentOwner ? (
              <Paragraph boldness={"semi"} rootClassName={"gx-member-role"}>
                {userRole}
              </Paragraph>
            ) : (
              <ListMemberRole
                email={email}
                name={isVerifiedUser ? fullName : email}
                onEmailResent={setIsEmailResent}
                removeUserHighlight={handleRemoveUserHighlight}
                userRole={userRole}
              />
            )}
          </React.Fragment>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
      {!highlightRemoveUser && isGCOM3686FFOn && (
        <FunctionalRoleComponent email={email} functionalRoleId={functionalRoleId} functionalRole={functionalRole} />
      )}
    </div>
  );
};

export default TeamMember;
