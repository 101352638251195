import { FC, Fragment, RefObject, useContext, useRef } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  Paragraph,
  TooltipV2,
  TypographyComponent,
  UsernameDisplay,
} from "gx-npm-ui";
import { GroupAddIcon, GroupPeople } from "gx-npm-icons";
import { ScoringMode, UUID } from "gx-npm-lib";
import { TeamManagementAppContext } from "../../../app.context";
import { CategoriesEvaluatorType } from "../../../app.types";
import styles from "./scoring-assignments-manage-list-item.styles.module.scss";
import { colorPalette } from "gx-npm-common-styles";
import { GCOM_3606__fontUpdate } from "../../../lib/feature-flags";

type ScoringManageListItemProps = {
  categoryName?: string;
  categoryId?: UUID;
  evaluators: CategoriesEvaluatorType[];
  handleClick?: (clickRefObject: RefObject<HTMLElement>, catId: UUID, catName: string) => void;
  selectedCategory?: UUID;
};

const ScoringManageListItem: FC<ScoringManageListItemProps> = ({
  categoryName = "",
  categoryId = "",
  evaluators = [],
  handleClick = (_clickRefObject: RefObject<HTMLElement>, _categoryId: UUID, _categoryName: string) => {},
  selectedCategory = "",
}) => {
  const { t } = useTranslation();
  const { scoringTeam } = useContext(TeamManagementAppContext);
  const { mode } = scoringTeam;
  const refAssignedLabel = useRef(null);
  const maxEvaluatorDisplay = 8;
  const evaluatorCount = evaluators.length;
  const additionalEvaluatorCount = evaluatorCount - maxEvaluatorDisplay;
  const additionalEvaluatorsToolTipText = evaluators.slice(maxEvaluatorDisplay).map((evaluator) => (
    <span key={evaluator.email}>
      {evaluator.hasViewed ? evaluator.fullName : evaluator.email}
      <br />
    </span>
  ));
  const renderAvatarPills = () => {
    if (mode === ScoringMode.AUTO_ASSIGNMENT) {
      return (
        <TooltipV2
          placement="bottom"
          title={t("Entire team scores")}
          PopperProps={{ modifiers: { offset: { offset: "0, 5" } } }}
        >
          <div className={classNames(styles.groupPeople)}>
            <GroupPeople title="auto assigned evaluators" />
          </div>
        </TooltipV2>
      );
    } else if (mode === ScoringMode.MANUAL_ASSIGNMENT) {
      if (evaluatorCount > 0) {
        const avatarPills = evaluators.slice(0, maxEvaluatorDisplay).map((evaluator) => {
          return (
            <TooltipV2
              key={`eval-pill-tt-${evaluator.email}`}
              placement="bottom"
              title={evaluator.hasViewed ? evaluator.fullName : evaluator.email}
              PopperProps={{ modifiers: { offset: { offset: "0, 5" } } }}
            >
              <span>
                <UsernameDisplay
                  ariaLabel="evaluator"
                  rootClassName={"gx-scoring-evaluators-username-display"}
                  name={evaluator.hasViewed ? evaluator.fullName : evaluator.email}
                  isUnknown={!evaluator.hasViewed}
                  isSmallerIcon={true}
                />
              </span>
            </TooltipV2>
          );
        });
        return (
          <Fragment>
            {avatarPills}
            {additionalEvaluatorCount > 0 && (
              <TooltipV2
                placement="bottom"
                PopperProps={{ modifiers: { offset: { offset: "0, 5" } } }}
                title={additionalEvaluatorsToolTipText}
              >
                <span aria-label="additional">
                  <TypographyComponent boldness="medium" rootClassName={styles.additionalEvaluatorsBadge} type="p4">
                    {`+${additionalEvaluatorCount}`}
                  </TypographyComponent>
                </span>
              </TooltipV2>
            )}
          </Fragment>
        );
      } else {
        return (
          <TooltipV2
            placement="bottom"
            title={t("No team members have been assigned to score.")}
            PopperProps={{
              modifiers: { offset: { offset: "0, 5" } },
            }}
          >
            <div className={styles.noUserAvatar}>
              <GroupAddIcon
                title="auto assigned evaluators"
                fillPath={colorPalette.neutrals.iron.hex}
                width={"20px"}
                height={"20px"}
              />
            </div>
          </TooltipV2>
        );
      }
    }
  };

  const renderAssignedLabel = () => {
    if (mode === ScoringMode.AUTO_ASSIGNMENT) {
      return (
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <div ref={refAssignedLabel}>
              <TypographyComponent
                boldness={"medium"}
                rootClassName={classNames("auto", styles.textAssigned)}
                styling={"p4"}
                color={"iron"}
              >
                {`${t("Team members assigned")} (${evaluatorCount})`}
              </TypographyComponent>
            </div>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <Paragraph
              boldness="medium"
              ref={refAssignedLabel}
              rootClassName={classNames("auto", styles.textAssigned)}
              type="p4"
            >
              {`${t("Team members assigned")} (${evaluatorCount})`}
            </Paragraph>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      );
    } else if (mode === ScoringMode.MANUAL_ASSIGNMENT) {
      return (
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <div ref={refAssignedLabel} className={classNames(styles.textAssigned)}>
              <TypographyComponent
                boldness={evaluatorCount > 0 ? "medium" : "regular"}
                color={evaluatorCount < 1 ? "iron" : "carbon"}
                styling={"p4"}
              >
                {evaluatorCount > 0 ? `${evaluatorCount} ${t("assigned")}` : t("None assigned")}
              </TypographyComponent>
            </div>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <Paragraph
              boldness={evaluatorCount > 0 ? "semi" : "regular"}
              ref={refAssignedLabel}
              rootClassName={classNames(styles.textAssigned, evaluatorCount < 1 && "unassigned")}
              type="p4"
            >
              {evaluatorCount > 0 ? `${evaluatorCount} ${t("assigned")}` : t("None assigned")}
            </Paragraph>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      );
    }
  };

  return (
    <li className={classNames(styles.scoringManageListItem, mode === ScoringMode.AUTO_ASSIGNMENT && "auto")}>
      <div
        className={classNames(styles.scoringManageListItemWrapper, selectedCategory === categoryId && "selected")}
        onClick={() => handleClick(refAssignedLabel, categoryId, categoryName)}
        onKeyDown={() => handleClick(refAssignedLabel, categoryId, categoryName)}
        role="button"
        tabIndex={0}
      >
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent boldness={"medium"} styling={"p2"}>
              {categoryName || t("Untitled category")}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <Paragraph boldness="semi" type="p2">
              {categoryName || t("Untitled category")}
            </Paragraph>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
        <div className={classNames(styles.evaluatorAvatarRoot)}>
          <div className={classNames(styles.otherEvaluators)}>{renderAvatarPills()}</div>
        </div>
        {renderAssignedLabel()}
      </div>
    </li>
  );
};

export default ScoringManageListItem;
