import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  Paragraph,
  TypographyComponent,
} from "gx-npm-ui";
import styles from "./dialog-not-email-domain-match.styles.module.scss";
import { GCOM_3606__fontUpdate } from "../../lib/feature-flags";

type DialogNotEmailDomainMatchProps = {
  isOpen?: boolean;
  onClose?: (_e: React.MouseEvent<HTMLButtonElement>) => void;
  onConfirm?: (_e: React.MouseEvent<HTMLButtonElement>) => void;
  sourceEmail?: string;
  targetEmail?: string;
  isConfirmed?: boolean;
};

const DialogNotEmailDomainMatch: React.FC<DialogNotEmailDomainMatchProps> = ({
  isOpen = false,
  onClose = (_e) => {},
  onConfirm = (_e) => {},
  sourceEmail = "",
  targetEmail = "",
  isConfirmed = false,
}) => {
  const { t } = useTranslation();
  const sourceEmailDomain = sourceEmail.split("@")[1];
  const targetEmailDomain = targetEmail.split("@")[1];

  const handleConfirm = (e: React.MouseEvent<HTMLButtonElement>) => {
    onConfirm(e);
  };
  return (
    <Dialog
      handleClose={onClose}
      height="720px"
      maxDialogWidth="699px"
      open={isOpen}
      rootClassName={styles.root}
      title={
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent boldness={"medium"} rootClassName={styles.titleContainer}>
              {t("Invitation verification required")}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <div className={styles.titleContainer}>{t("Invitation verification required")}</div>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      }
      body={
        <div className={styles.bodyContainer}>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent styling={"p2"}>
                {t("Hmmm. We noticed the email domain for this team member is different.")}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <Paragraph type={"p2"}>
                {t("Hmmm. We noticed the email domain for this team member is different.")}
              </Paragraph>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
          <br />
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent styling={"p2"}>
                {t("Your email domain is")}
                <TypographyComponent
                  element={"span"}
                  boldness={"medium"}
                >{` “@${sourceEmailDomain}” `}</TypographyComponent>
                {t("and you are inviting a collaborator from")}
                <TypographyComponent
                  element={"span"}
                  boldness={"medium"}
                >{` “@${targetEmailDomain}.” `}</TypographyComponent>
                {t(`Gartner BuySmart is only licensed for employees within your organization.`)}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <Paragraph type={"p2"}>
                {t("Your email domain is")}
                <span className={classNames(styles.emailDomain)}>{` “@${sourceEmailDomain}” `}</span>
                {t("and you are inviting a collaborator from")}
                <span className={classNames(styles.emailDomain)}>{` “@${targetEmailDomain}.” `}</span>
                {t(`Gartner BuySmart is only licensed for employees within your organization.`)}
              </Paragraph>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
          <br />
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent styling={"p2"}>
                {t(
                  `By clicking "Verify invitation and send," you are certifying the person being invited is an employee of your organization per BuySmart licensing requirements.`
                )}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <Paragraph type={"p2"}>
                {t(
                  `By clicking "Verify invitation and send," you are certifying the person being invited is an employee of your organization per BuySmart licensing requirements.`
                )}
              </Paragraph>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </div>
      }
      footer={
        <div className={styles.footerContainer}>
          <Button onClick={onClose} rootClassName="btn-tertiary">
            {t("CANCEL")}
          </Button>
          <div className={classNames(styles.verifyButton)}>
            <Button disabled={isConfirmed} onClick={handleConfirm} rootClassName="btn-primary">
              {t("VERIFY INVITATION AND SEND")}
            </Button>
          </div>
        </div>
      }
    />
  );
};

export default DialogNotEmailDomainMatch;
