import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createGenerateClassName, StylesProvider } from "@material-ui/core/styles";
import { messengerFlagLoader } from "gx-npm-lib";
import { FeatureFlagProvider } from "gx-npm-ui";
import App from "./app.component";
import { TeamManagementAppContextProvider } from "./app.context";

const generateClassName = createGenerateClassName({
  seed: "TeamManagement-App",
});

const Root = () => {
  return (
    <FeatureFlagProvider loader={messengerFlagLoader()}>
      <StylesProvider generateClassName={generateClassName}>
        <TeamManagementAppContextProvider>
          <BrowserRouter>
            <Routes>
              <Route path={"/s/evaluation/:initiativeId/:tab/team-management"} element={<App />} />
            </Routes>
          </BrowserRouter>
        </TeamManagementAppContextProvider>
      </StylesProvider>
    </FeatureFlagProvider>
  );
};

export default Root;
