// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nKiNnzU_DXvfOgfsXvWM{padding-right:81px}.nKiNnzU_DXvfOgfsXvWM .WUT35yF_Tz9Gs5dH_SAe{padding-bottom:56px}.nKiNnzU_DXvfOgfsXvWM .mOV39RginEFyFdIgx5A3{display:flex;align-items:center;gap:15px;padding-bottom:12px}.nKiNnzU_DXvfOgfsXvWM .ppeWHItClq_NFytEl9PR{color:var(--color-neutrals-quartz);width:400px}.nKiNnzU_DXvfOgfsXvWM .oZR6W34BlsJWs8PPIxri{width:400px;margin-bottom:56px}.nKiNnzU_DXvfOgfsXvWM .ahYecKOcaE1FArj1GrAE{background-color:var(--color-white);height:1px;opacity:.2;margin-bottom:12px}.nKiNnzU_DXvfOgfsXvWM .G531vpjokwH_qrT8oQIu{margin-bottom:16px}.nKiNnzU_DXvfOgfsXvWM .NN1AnxNWODzAWRcdn_xt .DVxl6Xnol0dz5NBEsajO{width:320px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/ribbon/researchSlideIn/components/market-guide-body.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,4CACE,mBAAA,CAEF,4CACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,mBAAA,CAGF,4CACE,kCAAA,CACA,WAAA,CAGF,4CACE,WAAA,CACA,kBAAA,CAEF,4CACE,mCAAA,CACA,UAAA,CACA,UAAA,CACA,kBAAA,CAEF,4CACE,kBAAA,CAGA,kEACE,WAAA","sourcesContent":[".contentBodyMidSectionLeft {\n  padding-right: 81px;\n  & .gx-gartner-mg-desc-text {\n    padding-bottom: 56px;\n  }\n  .contentBodyMidSectionTitleText {\n    display: flex;\n    align-items: center;\n    gap: 15px;\n    padding-bottom: 12px;\n  }\n\n  .contentBodyMidSectionDescText {\n    color: var(--color-neutrals-quartz);\n    width: 400px;\n  }\n\n  .contentBodyMidSectionDescTextGCOM3523 {\n    width: 400px;\n    margin-bottom: 56px;\n  }\n  .contentLearnMoreLine {\n    background-color: var(--color-white);\n    height: 1px;\n    opacity: 0.2;\n    margin-bottom: 12px;\n  }\n  .contentBodyMidSectionLearnMore {\n    margin-bottom: 16px;\n  }\n  .contentLink {\n    .contentBodyMidSectionLinkText {\n      width: 320px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentBodyMidSectionLeft": `nKiNnzU_DXvfOgfsXvWM`,
	"gx-gartner-mg-desc-text": `WUT35yF_Tz9Gs5dH_SAe`,
	"contentBodyMidSectionTitleText": `mOV39RginEFyFdIgx5A3`,
	"contentBodyMidSectionDescText": `ppeWHItClq_NFytEl9PR`,
	"contentBodyMidSectionDescTextGCOM3523": `oZR6W34BlsJWs8PPIxri`,
	"contentLearnMoreLine": `ahYecKOcaE1FArj1GrAE`,
	"contentBodyMidSectionLearnMore": `G531vpjokwH_qrT8oQIu`,
	"contentLink": `NN1AnxNWODzAWRcdn_xt`,
	"contentBodyMidSectionLinkText": `DVxl6Xnol0dz5NBEsajO`
};
export default ___CSS_LOADER_EXPORT___;
