import React, { useCallback, useContext, useEffect, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import {
  Button,
  ButtonLoader,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  Header,
  Loader,
  Paragraph,
  TypographyComponent,
} from "gx-npm-ui";
import { getAsyncRequest, InitStatus, putAsyncRequest, ScoringMode } from "gx-npm-lib";
import TeamScoringAuto from "../../assets/images/team-scoring-auto.svg";
import TeamScoringAutoSelected from "../../assets/images/team-scoring-auto-selected.svg";
import TeamScoringManual from "../../assets/images/team-scoring-manual.svg";
import TeamScoringManualSelected from "../../assets/images/team-scoring-manual-selected.svg";
import TeamScoringNotAvailable from "../../assets/images/team-scoring-not-available.svg";
import styles from "./scoring-assignments.styles.module.scss";
import ScoringModeChangeDialog from "./scoring-mode-change-dialog.component";
import ScoringAssignmentsManager from "./scoring-assignments-manage/scoring-assignments-manage.component";
import { TeamManagementAppContext } from "../../app.context";
import { GCOM_3606__fontUpdate } from "../../lib/feature-flags";

const ScoringAssignments = () => {
  const { t } = useTranslation();
  const {
    initiativeId,
    scoringTeam,
    setErrorMessage,
    setIsErrorSnackBarOpen,
    setScoringTeam,
    initiativeStatus,
    setInitiativeStatus,
    hasSelectedScoringAssignment,
    setHasSelectedScoringAssignment,
  } = useContext(TeamManagementAppContext);
  const [modeSelection, setModeSelection] = useState(ScoringMode.NONE);
  const [isChangeModeConfirmationViewOpen, setIsChangeModeConfirmationViewOpen] = useState(false);
  const [isModeSelectionView, setIsModeSelectionView] = useState(false);
  const [isLoadingScoring, setIsLoadingScoring] = useState(true);
  const [isUpdatingMode, setIsUpdatingMode] = useState(false);

  const loadScoringAssignmentData = useCallback(async () => {
    if (!initiativeId) {
      setIsLoadingScoring(false);
      return;
    }

    setIsLoadingScoring(true);
    try {
      const url = `/api/v3/initiatives/${initiativeId}/requirements/scoring-team`;
      const response = await getAsyncRequest(url);
      if (response.status !== 200 || !response.data?.data) {
        throw new Error(response.data?.systemMessage?.message);
      }
      const {
        hasSelectedScoringAssignment: _hasSelectedScoringAssignment,
        initiativeStatus: _initiativeStatus,
        mode,
        categories,
      } = response.data.data;
      setInitiativeStatus(_initiativeStatus);
      setHasSelectedScoringAssignment(_hasSelectedScoringAssignment);
      setScoringTeam({
        mode,
        categories,
      });
      setIsModeSelectionView(false);
      setModeSelection(
        !_hasSelectedScoringAssignment && mode === ScoringMode.NONE ? ScoringMode.AUTO_ASSIGNMENT : mode
      );
    } catch (errorMessage) {
      setErrorMessage("ERROR");
      setIsErrorSnackBarOpen(true);
    }
    setIsLoadingScoring(false);
  }, [
    initiativeId,
    setErrorMessage,
    setHasSelectedScoringAssignment,
    setInitiativeStatus,
    setIsErrorSnackBarOpen,
    setScoringTeam,
  ]);

  useEffect(() => {
    loadScoringAssignmentData();
  }, [loadScoringAssignmentData]);

  const handleCancelClick = () => {
    setIsChangeModeConfirmationViewOpen(false);
  };

  const handleSaveClick = () => {
    if (modeSelection !== scoringTeam.mode) {
      setIsChangeModeConfirmationViewOpen(true);
    } else {
      setIsModeSelectionView(false);
    }
  };

  const handleModeChange = () => {
    setIsModeSelectionView(true);
    setModeSelection(scoringTeam.mode);
  };

  const updateScoringMode = () => {
    const hasChangedMode = modeSelection !== scoringTeam.mode;
    if (hasChangedMode || hasSelectedScoringAssignment === false) {
      (async () => {
        setIsUpdatingMode(true);
        try {
          const url = `/api/v3/initiatives/${initiativeId}/scoring-mode`;
          const response = await putAsyncRequest(url, { scoringMode: modeSelection });
          if (response.status !== 200) {
            throw new Error(response.data?.systemMessage?.message);
          }
          setIsModeSelectionView(false);
          setIsChangeModeConfirmationViewOpen(false);
          loadScoringAssignmentData();
        } catch (errorMessage) {
          setErrorMessage("ERROR");
          setIsErrorSnackBarOpen(true);
        }
        setIsUpdatingMode(false);
      })();
    }
  };

  const renderAssignmentSelection = () => {
    return (
      <React.Fragment>
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent rootClassName={styles.scoringDescriptionGCOM3606} styling={"p3"} color={"coal"}>
              {t("Manage team members' scoring assignments across requirement categories.")}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <Paragraph rootClassName={styles.scoringDescription} type="p3">
              {t("Manage team members' scoring assignments across requirement categories.")}
            </Paragraph>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
        <React.Fragment>
          <div className={styles.selectionsContainer}>
            <div
              aria-label={`${t("Auto assignment")} is ${
                modeSelection === ScoringMode.AUTO_ASSIGNMENT ? "selected" : "not selected"
              }`}
              className={classNames(
                styles.selection,
                styles.firstButton,
                modeSelection === ScoringMode.AUTO_ASSIGNMENT && "selected"
              )}
              onClick={() => {
                setModeSelection(ScoringMode.AUTO_ASSIGNMENT);
              }}
              onKeyDown={(e) => {
                if (e.code === "Enter" || e.code === "Space") {
                  setModeSelection(ScoringMode.AUTO_ASSIGNMENT);
                }
              }}
              role="button"
              tabIndex={0}
            >
              <img
                alt="TeamScoringAuto"
                src={modeSelection === ScoringMode.AUTO_ASSIGNMENT ? TeamScoringAutoSelected : TeamScoringAuto}
              />
              <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                <FeatureFlagBooleanOn>
                  <TypographyComponent boldness={"medium"} rootClassName={styles.selectionTitle} styling={"p2"}>
                    {t("Auto Assignments")}
                  </TypographyComponent>
                </FeatureFlagBooleanOn>
                <FeatureFlagBooleanOff>
                  <Paragraph boldness="semi" rootClassName={styles.selectionTitle} type="p2">
                    {t("Auto Assignments")}
                  </Paragraph>
                </FeatureFlagBooleanOff>
              </FeatureFlagBooleanContainer>
              {
                <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                  <FeatureFlagBooleanOn>
                    <TypographyComponent boldness={"regular"} styling={"p4"}>
                      {t("Team members with Owner or Contributor access to the evaluation will")}{" "}
                      <TypographyComponent element={"span"} boldness={"medium"}>
                        {t("automatically")}
                      </TypographyComponent>{" "}
                      {t("be assigned to score all categories.")}
                    </TypographyComponent>
                  </FeatureFlagBooleanOn>
                  <FeatureFlagBooleanOff>
                    <Paragraph boldness="regular" type="p4">
                      {t("Team members with Owner or Contributor access to the evaluation will")}{" "}
                      <span className={"semi-bold"}>{t("automatically")}</span>{" "}
                      {t("be assigned to score all categories.")}
                    </Paragraph>
                  </FeatureFlagBooleanOff>
                </FeatureFlagBooleanContainer>
              }
            </div>

            <div
              aria-label={`${t("Manual assignment")} is ${
                modeSelection === ScoringMode.MANUAL_ASSIGNMENT ? t("selected") : t("not selected")
              }`}
              className={classNames(styles.selection, modeSelection === ScoringMode.MANUAL_ASSIGNMENT && "selected")}
              onClick={() => {
                setModeSelection(ScoringMode.MANUAL_ASSIGNMENT);
              }}
              onKeyDown={(e) => {
                if (e.code === "Enter" || e.code === "Space") {
                  setModeSelection(ScoringMode.MANUAL_ASSIGNMENT);
                }
              }}
              role="button"
              tabIndex={0}
            >
              <img
                alt="TeamScoringManual"
                src={modeSelection === ScoringMode.MANUAL_ASSIGNMENT ? TeamScoringManualSelected : TeamScoringManual}
              />
              <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                <FeatureFlagBooleanOn>
                  <TypographyComponent boldness={"medium"} rootClassName={styles.selectionTitle} styling={"p2"}>
                    {t("Manual Assignments")}
                  </TypographyComponent>
                  <TypographyComponent boldness={"regular"} styling={"p4"}>
                    {t("Evaluation owners will")} <span className={"semi-bold"}>{t("manually")}</span>{" "}
                    {t("assign team members to score each category.")}
                  </TypographyComponent>
                </FeatureFlagBooleanOn>
                <FeatureFlagBooleanOff>
                  <Paragraph boldness="semi" rootClassName={styles.selectionTitle} type="p2">
                    {t("Manual Assignments")}
                  </Paragraph>
                  <Paragraph boldness="regular" type="p4">
                    {t("Evaluation owners will")} <span className={"semi-bold"}>{t("manually")}</span>{" "}
                    {t("assign team members to score each category.")}
                  </Paragraph>
                </FeatureFlagBooleanOff>
              </FeatureFlagBooleanContainer>
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            {hasSelectedScoringAssignment === false ? (
              <ButtonLoader btnClass="btn-primary" isLoading={isUpdatingMode} onClick={updateScoringMode}>
                {t("Save and Continue")}
              </ButtonLoader>
            ) : (
              <div style={{ display: "flex" }}>
                <Button
                  onClick={() => {
                    setIsModeSelectionView(false);
                  }}
                  rootClassName={classNames(styles.buttonCancel, "btn-tertiary")}
                >
                  {t("Cancel")}
                </Button>
                <ButtonLoader btnClass="btn-primary" onClick={handleSaveClick}>
                  {t("Save")}
                </ButtonLoader>
              </div>
            )}
            <ScoringModeChangeDialog
              onCancelClick={handleCancelClick}
              mode={modeSelection}
              isOpen={isChangeModeConfirmationViewOpen}
              onChangeScoringMode={updateScoringMode}
              isLoading={isUpdatingMode}
            />
          </div>
        </React.Fragment>
      </React.Fragment>
    );
  };

  const renderNotStarted = () => {
    return (
      <div aria-label={t("not started view")}>
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent rootClassName={styles.scoringDescriptionGCOM3606} styling={"p3"}>
              {t("Manage team members' scoring assignments across requirement categories.")}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <Paragraph rootClassName={styles.scoringDescription} type="p3">
              {t("Manage team members' scoring assignments across requirement categories.")}
            </Paragraph>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
        <div className={styles.notAvailableContainer}>
          <img alt="TeamScoringAuto" src={TeamScoringNotAvailable} />
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent
                boldness={"semi"}
                rootClassName={styles.notAvailableTitleGCOM3606}
                color={"iron"}
                styling={"h5"}
              >
                {t("Scoring assignments not yet available.")}
              </TypographyComponent>
              <TypographyComponent
                boldness={"regular"}
                rootClassName={styles.notAvailableDescriptionGCOM3606}
                color={"iron"}
                styling={"p3"}
              >
                {t("You will be able to decide who is part of your scoring team once the evaluation has started.")}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <Header boldness="semi" rootClassName={styles.notAvailableTitle} type="h5">
                {t("Scoring assignments not yet available.")}
              </Header>
              <Paragraph boldness="regular" rootClassName={styles.notAvailableDescription} type="p3">
                {t("You will be able to decide who is part of your scoring team once the evaluation has started.")}
              </Paragraph>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    const isEvalPlanning = initiativeStatus === InitStatus.PLANNING;

    let scoringAssignmentContent = <></>;

    if (isEvalPlanning) {
      scoringAssignmentContent = renderNotStarted();
    } else if (!hasSelectedScoringAssignment || isModeSelectionView) {
      scoringAssignmentContent = renderAssignmentSelection();
    } else if (hasSelectedScoringAssignment && !isModeSelectionView) {
      scoringAssignmentContent = <ScoringAssignmentsManager onModeChange={handleModeChange} />;
    }

    return (
      scoringAssignmentContent && (
        <div aria-label="scoring assignment content" className={styles.scoringContent}>
          {scoringAssignmentContent}
        </div>
      )
    );
  };

  return (
    <div aria-label="scoring assignments" className={classNames(styles.slideInBody, styles.scoringAssignment)}>
      <section className={styles.titleContainer}>
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent boldness={"medium"} styling={"h3"} rootClassName={styles.title}>
              {t("Manage your scoring team")}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <h3 className={styles.title}>{t("Manage your scoring team")}</h3>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
        {isLoadingScoring ? <Loader size={48} variant="indeterminate" /> : renderBody()}
      </section>
    </div>
  );
};

export default ScoringAssignments;
