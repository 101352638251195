// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SPIxCzUCOHFjUWljJxeF.gx-score-mode-change-dialog [role=dialog]{height:275px}.ImPp_FQ3ZtJwO_geYUQi{display:inline-flex;float:right;margin-top:4px;padding:0 12px}.ImPp_FQ3ZtJwO_geYUQi .btn-tertiary{margin-right:24px}`, "",{"version":3,"sources":["webpack://./src/sections/scoring-assignments/scoring-mode-change-dialog.styles.module.scss"],"names":[],"mappings":"AAAA,gEACE,YAAA,CAGF,sBACE,mBAAA,CACA,WAAA,CACA,cAAA,CACA,cAAA,CAGF,oCACE,iBAAA","sourcesContent":[".root:global(.gx-score-mode-change-dialog) [role=dialog] {\n  height: 275px;\n}\n\n.pubDialogFooterRoot {\n  display: inline-flex;\n  float: right;\n  margin-top: 4px;\n  padding: 0 12px;\n}\n\n.pubDialogFooterRoot :global(.btn-tertiary) {\n  margin-right: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `SPIxCzUCOHFjUWljJxeF`,
	"pubDialogFooterRoot": `ImPp_FQ3ZtJwO_geYUQi`
};
export default ___CSS_LOADER_EXPORT___;
