// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AG41BC6YVtE7UHlwtZIy [role=dialog]{max-width:699px}.qosGgeaKuNHnUfxSCoQW{margin:0px 18px;width:603px}.qosGgeaKuNHnUfxSCoQW .YvVP63y6gu69MnM3Up0s{font-weight:var(--weight-semi-bold);font-variation-settings:"wght" var(--weight-semi-bold)}.lZWjuEj7RhBE73c5SXGQ{bottom:0;display:flex;float:right;margin-bottom:46px;margin-right:36px;padding:0px 18px;position:absolute;right:0}.lZWjuEj7RhBE73c5SXGQ .a0Y1YadJE3sBb1X8bBrA{margin-left:24px}.lwFVWw2rZZ_rZL9aXe6L{margin-bottom:28px;margin-top:20px;padding:0px 18px}`, "",{"version":3,"sources":["webpack://./src/sections/team-management/dialog-not-email-domain-match.styles.module.scss"],"names":[],"mappings":"AAAA,oCACE,eAAA,CAGF,sBACE,eAAA,CACA,WAAA,CAGF,4CACE,mCAAA,CACA,sDAAA,CAGF,sBACE,QAAA,CACA,YAAA,CACA,WAAA,CACA,kBAAA,CACA,iBAAA,CACA,gBAAA,CACA,iBAAA,CACA,OAAA,CAGF,4CACE,gBAAA,CAGF,sBACE,kBAAA,CACA,eAAA,CACA,gBAAA","sourcesContent":[".root [role=dialog]{\n  max-width: 699px;\n}\n\n.bodyContainer {\n  margin: 0px 18px;\n  width: 603px;\n}\n\n.bodyContainer .emailDomain {\n  font-weight: var(--weight-semi-bold);\n  font-variation-settings: \"wght\" var(--weight-semi-bold);\n}\n\n.footerContainer {\n  bottom: 0;\n  display: flex;\n  float: right;\n  margin-bottom: 46px;\n  margin-right: 36px;\n  padding: 0px 18px;\n  position: absolute;\n  right: 0;\n}\n\n.footerContainer .verifyButton {\n  margin-left: 24px;\n}\n\n.titleContainer {\n  margin-bottom: 28px;\n  margin-top: 20px;\n  padding: 0px 18px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `AG41BC6YVtE7UHlwtZIy`,
	"bodyContainer": `qosGgeaKuNHnUfxSCoQW`,
	"emailDomain": `YvVP63y6gu69MnM3Up0s`,
	"footerContainer": `lZWjuEj7RhBE73c5SXGQ`,
	"verifyButton": `a0Y1YadJE3sBb1X8bBrA`,
	"titleContainer": `lwFVWw2rZZ_rZL9aXe6L`
};
export default ___CSS_LOADER_EXPORT___;
