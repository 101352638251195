import { PopoverMenu, SnackbarBanner, TextField } from "gx-npm-ui";
import styles from "./functional-role.styles.module.scss";
import { ChangeEvent, useContext, useRef, useState } from "react";
import { postAsyncRequest, putAsyncRequest, UUID } from "gx-npm-lib";
import { TeamManagementAppContext } from "../../../app.context";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

type FunctionalRoleComponentProps = {
  functionalRole: string;
  functionalRoleId: UUID;
  email: string;
};

const FunctionalRoleComponent = (props: FunctionalRoleComponentProps) => {
  const selectParentRef = useRef<HTMLDivElement>(null);
  const { initiativeId } = useContext(TeamManagementAppContext);
  const [functionalRoleId, setFunctionalRoleId] = useState(props.functionalRoleId);
  const [value, setValue] = useState(props.functionalRole);
  const [updatedValue, setUpdatedValue] = useState(props.functionalRole);
  const [hasError, setHasError] = useState(false);
  const { t } = useTranslation();
  const functionalRoleMenuItems = [
    {
      name: t("Don’t see your function? Type it in and press Enter to add it."),
      index: -1,
      rootClassName: classNames(styles.infoOption, styles.menuOption),
    },
    { name: t("Sponsor"), index: 0, rootClassName: styles.menuOption },
    { name: t("Functional SME"), index: 1, rootClassName: styles.menuOption },
    { name: t("Technical SME"), index: 2, rootClassName: styles.menuOption },
    { name: t("Finance"), index: 3, rootClassName: styles.menuOption },
    { name: t("Legal"), index: 4, rootClassName: styles.menuOption },
    { name: t("Procurement/Vendor Management"), index: 5, rootClassName: styles.menuOption },
    { name: t("Operations"), index: 6, rootClassName: styles.menuOption },
  ];

  const handleSelection = async (index: number) => {
    const selectedFunctionalRole = functionalRoleMenuItems.find((functionalRole) => functionalRole.index === index);
    if (selectedFunctionalRole && selectedFunctionalRole.name !== value) {
      setValue(selectedFunctionalRole.name);
      await upsertFunctionalRole(selectedFunctionalRole.name, true);
    }
  };

  const upsertFunctionalRole = async (functionalRole: string, isSelectedFromList: boolean) => {
    if (functionalRoleId && functionalRole !== updatedValue) {
      const response = await putAsyncRequest(
        `api/v2/initiatives/${initiativeId}/team/functional-role/${functionalRoleId}`,
        {
          functionalRole: functionalRole,
          isCustom: !isSelectedFromList,
        }
      );
      if (response.status !== 200) {
        setHasError(true);
      } else {
        setUpdatedValue(functionalRole);
      }
    } else if (!functionalRoleId && functionalRole) {
      const response = await postAsyncRequest(`api/v2/initiatives/${initiativeId}/team/functional-role`, {
        email: props.email,
        functionalRole: functionalRole,
        isCustom: !isSelectedFromList,
      });
      if (response.status === 201 && response.data.data) {
        const newFunctionalRoleId = response.data.data;
        setFunctionalRoleId(newFunctionalRoleId);
        setUpdatedValue(functionalRole);
      } else {
        setHasError(true);
      }
    }
  };

  const handleInputChange = async (event: ChangeEvent) => {
    const { value: newValue } = event.target as HTMLInputElement;
    setValue(newValue);
  };

  const handleFocusOut = async () => {
    await upsertFunctionalRole(value, false);
  };

  const handleKeyDown = async (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      (document.activeElement as HTMLElement).blur();
    }
  };

  return (
    <div className={styles.root}>
      <TextField
        onFocus={() => {
          (selectParentRef.current?.firstElementChild as HTMLElement).click();
        }}
        showLabel={true}
        value={value}
        onChange={handleInputChange}
        label={value ? t("Function") : t("Select function")}
        rootClassName={styles.textField}
        onBlur={handleFocusOut}
        onKeyDown={handleKeyDown}
        multilinev2={true}
        maxAllowedChars={50}
      />
      <div ref={selectParentRef} className={styles.selectFieldContainer}>
        <PopoverMenu
          useIconButton={false}
          usePortal={true}
          onClick={handleSelection}
          menuItems={functionalRoleMenuItems}
          rootClassName={classNames(styles.selectField)}
          showSelectedOption={false}
          iconType={"arrowDynamic"}
        />
      </div>
      <SnackbarBanner isDefaultErrorMessage={true} isOpen={hasError} setIsOpen={setHasError} type="ERROR" />
    </div>
  );
};

export default FunctionalRoleComponent;
