import { useEffect, useRef } from "react";
import PropTypes from "prop-types";

const propTypes = { isScrolled: PropTypes.bool };
const ScrollToRef = ({ isScrolled = false }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isScrolled || !ref?.current?.scrollIntoView) {
      return;
    }

    const options = { behavior: "smooth" as ScrollBehavior, block: "center" as ScrollLogicalPosition };

    ref.current.scrollIntoView(options);
  }, [isScrolled]);
  return <div ref={ref} style={{ height: 0 }} />;
};

ScrollToRef.propTypes = propTypes;
export default ScrollToRef;
