import React from "react";
import { DownloadOnTheAppStoreBadgeIcon, GetItOnGooglePlayBadgeIcon } from "gx-npm-icons";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./gartner-footer.styles";
import { TypographyComponent } from "../typography/typography.component";

const useStyles = makeStyles(() => styles);

export const GartnerFooter = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <div className={classes.footerContainer}>
        <div>
          <div className={classes.linkGroup}>
            <a href="https://www.gartner.com/en/about/policies/overview">
              <TypographyComponent rootClassName={classes.link} styling={"p4"} color={"slate"} boldness={"medium"}>
                {t("POLICIES")}
              </TypographyComponent>
            </a>
            <a href="https://www.gartner.com/en/about/policies/privacy">
              <TypographyComponent rootClassName={classes.link} styling={"p4"} color={"slate"} boldness={"medium"}>
                {t("PRIVACY POLICY")}
              </TypographyComponent>
            </a>
            <a href="https://www.gartner.com/en/about/policies/terms-of-use">
              <TypographyComponent rootClassName={classes.link} styling={"p4"} color={"slate"} boldness={"medium"}>
                {t("TERMS OF USE")}
              </TypographyComponent>
            </a>
            <a href="https://www.gartner.com/en/about/ombuds">
              <TypographyComponent rootClassName={classes.link} styling={"p4"} color={"slate"} boldness={"medium"}>
                {t("OMBUDS")}
              </TypographyComponent>
            </a>
          </div>
          <div className={classes.copyright}>
            <TypographyComponent styling={"p4"} element={"span"} color={"slate"}>
              &copy; {t("2024 Gartner, Inc. and/or its affiliates. All rights reserved.")}
            </TypographyComponent>
          </div>
        </div>
        <div className={classes.appSection}>
          <TypographyComponent styling={"h5"} color={"gartnerBlue"} rootClassName={classes.appTitle}>
            Get the App
          </TypographyComponent>

          <div className={classes.badgeContainer}>
            <a
              href="https://play.google.com/store/apps/details?id=com.gartner.mygartner&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              aria-label={t("Get the Gartner App on Google play")}
            >
              <GetItOnGooglePlayBadgeIcon />
            </a>
            <a
              aria-label={t("Get the Gartner App on Apple store")}
              href={"https://apps.apple.com/us/app/my-gartner-mobile/id1202220943?itsct=apps_box_link&itscg=30200"}
            >
              <DownloadOnTheAppStoreBadgeIcon />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
