import { createContext, ReactNode, useState } from "react";
import { InitStatus, ScoringMode, UUID } from "gx-npm-lib";
import { ScoringTeamType, TeamManagementAppContextValue, TeamMembersType } from "./app.types";
import { TeamManagementTabs } from "./lib";

const TeamManagementAppContext = createContext<TeamManagementAppContextValue>({} as TeamManagementAppContextValue);
const TeamManagementAppContextProvider = (props: { children: ReactNode | Array<ReactNode> }) => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [initiativeId, setInitiativeId] = useState<UUID>("");
  const [initiativeStatus, setInitiativeStatus] = useState<InitStatus | "">("");
  const [isDrawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [isErrorSnackBarOpen, setIsErrorSnackBarOpen] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isSuccessful, setSuccessful] = useState<boolean>(false);
  const [newlyDisplayedMemberEmail, setNewlyDisplayedMemberEmail] = useState<string>("");
  const [notificationEmail, setNotificationEmail] = useState<string>("");
  const [hasSelectedScoringAssignment, setHasSelectedScoringAssignment] = useState<boolean>(false);
  const [scoringTeam, setScoringTeam] = useState<ScoringTeamType>({
    categories: [],
    mode: ScoringMode.NONE,
  });
  const [selectedTab, setSelectedTab] = useState<TeamManagementTabs>(TeamManagementTabs.TEAM_MANAGEMENT);
  const [sourceEmail, setSourceEmail] = useState<string>("");
  const [teamMembers, setTeamMembers] = useState<Array<TeamMembersType>>([]);

  const contextValue = {
    errorMessage,
    initiativeId,
    initiativeStatus,
    isDrawerOpen,
    isErrorSnackBarOpen,
    isLoading,
    isSuccessful,
    newlyDisplayedMemberEmail,
    notificationEmail,
    scoringTeam,
    selectedTab,
    sourceEmail,
    teamMembers,
    hasSelectedScoringAssignment,
    setDrawerOpen,
    setErrorMessage,
    setIsErrorSnackBarOpen,
    setScoringTeam,
    setSelectedTab,
    setInitiativeId,
    setInitiativeStatus,
    setLoading,
    setNewlyDisplayedMemberEmail,
    setSourceEmail,
    setSuccessful,
    setNotificationEmail,
    setTeamMembers,
    setHasSelectedScoringAssignment,
  };

  return <TeamManagementAppContext.Provider value={contextValue}>{props.children}</TeamManagementAppContext.Provider>;
};

export { TeamManagementAppContext, TeamManagementAppContextProvider };
