export const compareEvaluatorSort = (
  a: { fullName: string; email: string },
  b: { fullName: string; email: string }
) => {
  return (
    compareStringWithEmptyStringLastSort(a.fullName, b.fullName) ||
    compareStringWithEmptyStringLastSort(a.email, b.email)
  );
};

const FIRST_IS_GREATER = 1;
const SECOND_IS_GREATER = -1;
const ARE_EQUAL = 0;

const compareStringWithEmptyStringLastSort = (a: string, b: string) => {
  if (a === b) {
    return ARE_EQUAL;
  }
  if (a === "") {
    return FIRST_IS_GREATER;
  }
  if (b === "") {
    return SECOND_IS_GREATER;
  }
  return a.localeCompare(b);
};
