import React, { useContext, useEffect, useRef, useState } from "react";
import { Collapse } from "@material-ui/core";
import { TeamManagementAppContext } from "../../app.context";
import ScrollToRef from "../shared/scrollToRef/scroll-to-ref";
import TeamMember from "./team-member.component";
import styles from "./team-members-drawer.styles.module.scss";
import classNames from "classnames";

const TeamMembersDrawer = () => {
  const { newlyDisplayedMemberEmail, notificationEmail, teamMembers } = useContext(TeamManagementAppContext);
  const [scrollToEmail, setScrollToEmail] = useState("");
  const memberAvatarRootEl = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setScrollToEmail(newlyDisplayedMemberEmail);
    const timeoutId = setTimeout(() => {
      setScrollToEmail("");
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, [teamMembers.length, setScrollToEmail, newlyDisplayedMemberEmail]);

  return (
    <div
      aria-label={"Team Member List"}
      className={classNames(styles.memberAvatarRoot, "list-fade-bottom")}
      ref={memberAvatarRootEl}
    >
      {teamMembers.map(({ email, fullName, hasViewed, isDeleted, role, functionalRole, functionalRoleId }) => (
        <Collapse className={styles.teamMemberCollapse} timeout={{ enter: 400, exit: 500 }} in={!isDeleted} key={email}>
          <div>
            <TeamMember
              email={email}
              fullName={fullName}
              isVerifiedUser={hasViewed}
              triggerNotification={email === notificationEmail}
              userRole={role}
              functionalRoleId={functionalRoleId}
              functionalRole={functionalRole}
            />
          </div>
          <ScrollToRef isScrolled={email === scrollToEmail} />
        </Collapse>
      ))}
    </div>
  );
};

export default TeamMembersDrawer;
