// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EKtPiSnRqwNufgOgO5dV .gx-dialog-body{margin-left:-36px;width:600px}.NUcfQn2H5sVnkmTcjQbQ{padding:24px 36px 56px 36px}.cySO4yIW4ZJQS7avUswv{background:var(--color-interactions-light-berry);padding:56px 0 74px 36px}.cySO4yIW4ZJQS7avUswv .sxQpPCiDJzacj9_FV4ko{display:block}.cySO4yIW4ZJQS7avUswv .noksg40QEOA_0Ge4DwZD{position:relative}.cySO4yIW4ZJQS7avUswv .noksg40QEOA_0Ge4DwZD .pLiwZ1hsBo5yDwDJULNC{bottom:-74px;position:absolute;right:0}.QN4CY46DmUgMVk9F0FuF{align-items:center;display:flex;justify-content:flex-end}.QN4CY46DmUgMVk9F0FuF .JOSjAbPHk_3KBUZeQuIo{margin-right:24px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/free-trial/free-trial-init-create-modal/free-trial-init-create-modal.styles.module.scss"],"names":[],"mappings":"AACE,sCACE,iBAAA,CACA,WAAA,CAIJ,sBACE,2BAAA,CAGF,sBACE,gDAAA,CACA,wBAAA,CAEA,4CACE,aAAA,CAEF,4CACE,iBAAA,CAEA,kEACE,YAAA,CACA,iBAAA,CACA,OAAA,CAKN,sBACE,kBAAA,CACA,YAAA,CACA,wBAAA,CAEA,4CACE,iBAAA","sourcesContent":[".dialogRoot {\n  & :global(.gx-dialog-body) {\n    margin-left: -36px;\n    width: 600px;\n  }\n}\n\n.subtitleParagraph {\n  padding: 24px 36px 56px 36px\n}\n\n.remainingInitsHeading {\n  background: var(--color-interactions-light-berry);\n  padding: 56px 0 74px 36px;\n\n  & .headingSecondLine {\n    display: block;\n  }\n  & .cornerImgContainer {\n    position: relative;\n\n    & .cornerImg {\n      bottom: -74px;\n      position: absolute;\n      right: 0;\n    }\n  }\n}\n\n.footerButtonContainer {\n  align-items: center;\n  display: flex;\n  justify-content: flex-end;\n\n  & .cancelButton {\n    margin-right: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogRoot": `EKtPiSnRqwNufgOgO5dV`,
	"subtitleParagraph": `NUcfQn2H5sVnkmTcjQbQ`,
	"remainingInitsHeading": `cySO4yIW4ZJQS7avUswv`,
	"headingSecondLine": `sxQpPCiDJzacj9_FV4ko`,
	"cornerImgContainer": `noksg40QEOA_0Ge4DwZD`,
	"cornerImg": `pLiwZ1hsBo5yDwDJULNC`,
	"footerButtonContainer": `QN4CY46DmUgMVk9F0FuF`,
	"cancelButton": `JOSjAbPHk_3KBUZeQuIo`
};
export default ___CSS_LOADER_EXPORT___;
