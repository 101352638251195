import React, { useContext, useEffect, useState } from "react";
import {
  deleteAsyncRequest,
  FreeTrialRole,
  InitUserRole,
  InitUserRoleLabel,
  postAsyncRequest,
  useUserState,
} from "gx-npm-lib";
import { CheckmarkIconV2, MultiplyIcon, RemoveUserIcon } from "gx-npm-icons";
import { MiniButton, PopoverMenu, useFeatureFlag } from "gx-npm-ui";
import styles from "./list-member-role.styles.module.scss";
import { colorPalette } from "gx-npm-common-styles";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import DialogUserRemove from "./dialog-user-remove.component";
import DialogUserRoleChange from "./dialog-user-role-change.component";
import { ClickAwayListener } from "@material-ui/core";
import { TeamManagementAppContext } from "../../app.context";
import { TeamMembersResponse } from "../../app.types";
import {
  GCOM_2917_GCOM_2989__userAccessUpdateCopyChange,
  GCOM_3479__functionalRoleUpdates,
} from "../../lib/feature-flags";

const roleIndex_Mapper = {
  [InitUserRole.VIEWER]: 0,
  [InitUserRole.CONTRIBUTOR]: 1,
  [InitUserRole.OWNER]: 2,
};

type ListMemberRoleProps = {
  email?: string;
  isOwnerRoleAllowed?: boolean;
  name?: string;
  onEmailResent?: (_e: boolean) => void;
  removeUserHighlight?: (_e: boolean) => void;
  userRole: InitUserRole;
};

const ListMemberRole: React.FC<ListMemberRoleProps> = ({
  email = "",
  isOwnerRoleAllowed = true,
  name = "",
  onEmailResent = () => {},
  removeUserHighlight = (_e) => {},
  userRole,
}) => {
  const {
    hasSelectedScoringAssignment,
    initiativeId,
    setErrorMessage,
    setLoading,
    setNotificationEmail,
    setTeamMembers,
    teamMembers,
  } = useContext(TeamManagementAppContext);
  const isGCOM2989FFOn = useFeatureFlag(GCOM_2917_GCOM_2989__userAccessUpdateCopyChange);
  const isGCOM3686FFOn = useFeatureFlag(GCOM_3479__functionalRoleUpdates);
  const { freeTrialRole } = useUserState();
  const isTrialUser = !!freeTrialRole && Object.values(FreeTrialRole).includes(freeTrialRole);

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);
  const [confirmChangeRoleDialog, setConfirmChangeRoleDialog] = useState(false);
  const isOwnerOrEditor = userRole !== InitUserRole.VIEWER;
  const { t } = useTranslation();
  const [menuIndex, setMenuIndex] = useState(roleIndex_Mapper[userRole]);

  useEffect(() => {
    setMenuIndex(roleIndex_Mapper[userRole]);
  }, [userRole]);

  useEffect(() => {
    removeUserHighlight(confirmDelete);
  }, [confirmDelete, removeUserHighlight]);

  const handleMenuItemClick = (event: number) => {
    switch (event) {
      case 4:
        setConfirmDelete(true);
        break;
      case 3:
        onEmailResent(true);
        handleResendNotification();
        break;
      case 0:
        if (hasSelectedScoringAssignment) {
          setConfirmChangeRoleDialog(true);
        } else {
          handleChangeUserRole(event);
        }
        break;
      default:
        handleChangeUserRole(event);
    }
  };

  const handleRoleChange = (newRole: InitUserRole) => {
    if (userRole !== newRole) {
      (async () => {
        setLoading(true);

        try {
          const urlInvite = `/api/v4/initiatives/${initiativeId}/invite`;
          const responseInvite: TeamMembersResponse = await postAsyncRequest(urlInvite, {
            email,
            role: newRole,
          });
          if (responseInvite.status !== 201) {
            throw new Error(responseInvite.data?.systemMessage?.message || "ERROR");
          }

          const updatedTeamMembers = teamMembers.map((member) => {
            if (member.email === email) {
              return { ...member, role: newRole };
            } else {
              return member;
            }
          });

          setTeamMembers(updatedTeamMembers);
        } catch (err) {
          setErrorMessage(err.message);
        }

        setLoading(false);
      })();
    }
  };

  const handleRemoveTeamMember = () => {
    (async () => {
      setLoading(true);

      try {
        const urlInvite = `/api/v2/initiatives/${initiativeId}/invite`;
        const responseInvite: TeamMembersResponse = await deleteAsyncRequest(urlInvite, { data: { email } });
        if (responseInvite.status !== 200) {
          throw new Error(responseInvite.data?.systemMessage?.message || "ERROR");
        }

        setTeamMembers((prev) => prev.map((member) => ({ ...member, isDeleted: member.email === email })));
        setTimeout(() => {
          setTeamMembers((prev) => prev.filter((member) => member.email !== email));
        }, 500);
      } catch (err) {
        setErrorMessage(err.message);
      }

      setLoading(false);
    })();
  };

  const handleResendNotification = () => {
    (async () => {
      setLoading(true);

      try {
        const urlInvite = `/api/v2/initiatives/${initiativeId}/invite/resend-notification`;
        const responseInvite: TeamMembersResponse = await postAsyncRequest(urlInvite, { email });
        if (responseInvite.status !== 201) {
          throw new Error(responseInvite.data?.systemMessage?.message || "ERROR");
        }

        setNotificationEmail(email);
      } catch (err) {
        setErrorMessage(err.message);
      }

      setLoading(false);
    })();
  };

  const handleRemoveUser = () => {
    setConfirmDelete(false);
    if (hasSelectedScoringAssignment && isOwnerOrEditor) {
      setConfirmDeleteDialog(true);
    } else {
      handleRemoveTeamMember();
    }
  };

  const handleRemoverUserCancel = () => {
    setConfirmDelete(false);
  };

  const handleDialogCancel = () => {
    setConfirmDeleteDialog(false);
    setConfirmChangeRoleDialog(false);
  };

  const handleRemoveUserDialogConfirm = () => {
    setConfirmDeleteDialog(false);
    handleRemoveTeamMember();
  };

  const handleChangeUserRole = (event: number) => {
    let role;
    switch (event) {
      case 2:
        role = InitUserRole.OWNER;
        break;
      case 1:
        role = InitUserRole.CONTRIBUTOR;
        break;
      default:
        role = InitUserRole.VIEWER;
    }
    setMenuIndex(event);
    handleRoleChange(role);
    setConfirmChangeRoleDialog(false);
  };

  const tooltipTitle = isTrialUser
    ? t("In the free trial version of BuySmart, evaluation ownership is not transferable.")
    : t("Team members with email domains different from the creator's cannot be set to the owner role.");
  const createMenuItemMapper = () => {
    return [
      {
        index: 0,
        name: InitUserRoleLabel.VIEWER,
        menuListIcon: userRole === InitUserRole.VIEWER && <CheckmarkIconV2 />,
      },
      {
        index: 1,
        name: InitUserRoleLabel.CONTRIBUTOR,
        menuListIcon: userRole === InitUserRole.CONTRIBUTOR && <CheckmarkIconV2 />,
      },
      {
        index: 2,
        name: InitUserRoleLabel.OWNER,
        menuListIcon: userRole === InitUserRole.OWNER && <CheckmarkIconV2 />,
        disabled: !isOwnerRoleAllowed || isTrialUser,
        showTooltip: !isOwnerRoleAllowed || isTrialUser,
        tooltipOptions: {
          left: "-246px",
          text: tooltipTitle,
          title: tooltipTitle,
          top: "-26px",
          width: "240px",
        },
      },
      {
        index: 3,
        name: "Resend notification",
      },
      {
        index: 4,
        name: "Remove user",
        rootClassName: "poison-cherry",
      },
    ];
  };

  return (
    <div className={classNames(styles.root, isGCOM3686FFOn && styles.rootV2)}>
      <DialogUserRemove
        isOpen={confirmDeleteDialog}
        title={t("Remove team member")}
        name={name}
        confirmText={t("REMOVE USER")}
        onCancel={handleDialogCancel}
        onConfirm={handleRemoveUserDialogConfirm}
        text={
          isGCOM2989FFOn
            ? t(
                "from your evaluation team will delete any scores they have already entered and remove any checklist assignments they have."
              )
            : t("will delete any scores they have already entered as part of the evaluation.")
        }
      />
      <DialogUserRoleChange
        isOpen={confirmChangeRoleDialog}
        title={t("Change team member role?")}
        name={name}
        confirmText={t("change role")}
        onCancel={handleDialogCancel}
        onConfirm={() => {
          handleChangeUserRole(0);
        }}
        userRole={userRole}
      />

      {confirmDelete ? (
        <ClickAwayListener onClickAway={() => setConfirmDelete(false)}>
          <div
            className={classNames(
              styles.deleteConfirmation,
              "gx-delete-confirm",
              isGCOM3686FFOn && styles.deleteConfirmationV2
            )}
          >
            <MiniButton onClick={handleRemoverUserCancel}>
              <MultiplyIcon fillPath={colorPalette.interactions.blueBerry.hex} />
              {t("Cancel")}
            </MiniButton>
            <MiniButton onClick={handleRemoveUser} rootClassName="destructive-mini-btn">
              <RemoveUserIcon />
              {t("Remove user")}
            </MiniButton>
          </div>
        </ClickAwayListener>
      ) : (
        <PopoverMenu
          usePortal={true}
          isClosedOnScroll={true}
          menuItems={createMenuItemMapper()}
          onClick={handleMenuItemClick}
          selectedIndex={menuIndex}
          iconType={"arrowDynamic"}
          useIconButton={true}
          rootClassName={isGCOM3686FFOn ? styles.popperIcon : ""}
        />
      )}
    </div>
  );
};

export default ListMemberRole;
